
//Так приятней :)
window.log = function(param){
    console.log(param);
};
$(window).load(function() {

	$(".loader_inner").fadeOut();
	$(".loader").delay(400).fadeOut("slow");
});

$(document).ready(function(){
$('a[href*=#]').bind("click", function(e){
	$('a[href^="#"]').removeClass("active");
		$(this).addClass("active");
var anchor = $(this);
$('html, body').stop().animate({
scrollTop: $(anchor.attr('href')).offset().top
}, 1000);
e.preventDefault();
});
return false;
});
$(".toggle_mnu").click(function() {
		$(".top_mnu").toggleClass("active");
		if($(".top_mnu").hasClass("active")){
			$(".header-text").css('opacity', '0');
			$(".header-text").css('z-index', '-1');
		}else{
			$(".header-text").css('opacity', '1');
			$(".header-text").css('z-index', '1');
		}
	});
$(".toggle_mnu").click(function() {
		$(".sandwich").toggleClass("visible");
	});
	// $(".toggle_mnu").click(function() {
	// 	if ($(".top_mnu").is(":visible")) {
	// 		$(".top_text").fadeIn(800);
	// 		$(".top_mnu").toggleClass("active");
	// 	} else {
	// 		$(".top_text").fadeOut(200);
	// 		$(".top_mnu").toggleClass("active");
	// 	};
	// });
if ($(window).width() <= 768) {
		$("#img_768_t").after($("#img_768"));
		function heightDetect() {
		$(".main_head").css("height", $(window).height());
	};
	heightDetect();
	$(window).resize(function() {
		heightDetect();
	});
};
if ($(window).width() <= 1124) {
		$(".row-top-p").removeClass("row");
};
	if ($(window).width() <= 600) {
		$("footer .col-xs-12").removeClass("col-md-3 ");
	$("footer .col-xs-12").removeClass("tel-footer");
};
$(".owl-text").owlCarousel({
		margin: 350,
		item: 1,
		pagination: false,
		navElement:'<div class="btn-owl"><div class="btn-prev"></div><div class="btn-next"></div></div>',
        navigation : true,
        navigationText : [
			'<div class="btn-owl"><div class="btn-prev"></div><div class="btn-next"></div></div>'
			],
		center: true,
		autoWidth: true,
        slideSpeed : 300,
        paginationSpeed : 400,
        stopOnHover : true,
        singleItem : true,
        autoPlay : false
   });

    $('#count').viewportChecker({
        callbackFunction: function(elem, action){
		$('#count-1').animateNumber({ number: 500},3000);
		$('#count-2').animateNumber({ number: 42},3000);
		$('#count-3').animateNumber({ number: 9},3000);
		$('#count-4').animateNumber({ number: 89},3000);
	},
});
// document.addEventListener('DOMContentLoaded', function() {
// 	document.getElementsByClassName('price-col').classList.removeClass('writing')

// });